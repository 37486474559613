// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("/opt/build/repo/src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-archive-jsx": () => import("/opt/build/repo/src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-cast-jsx": () => import("/opt/build/repo/src/pages/cast.jsx" /* webpackChunkName: "component---src-pages-cast-jsx" */),
  "component---src-pages-credits-jsx": () => import("/opt/build/repo/src/pages/credits.jsx" /* webpackChunkName: "component---src-pages-credits-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

